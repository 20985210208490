<template>
    <div>
        <div class="main-container">
            <div class="container codigo">
                <h3>Código de Barras</h3>
                <h2>{{ produto.codigo_de_barras }}</h2>
            </div>
            <div class="container descricao">
                <h3>Descrição</h3>
                <h2>{{ produto.Descricao }}</h2>
            </div>
            <div class="container validade">
                <h3>Validades Incluídas</h3>
                <h2>
                    <ul>
                        <li
                            class="linha-validade"
                            v-for="(validades, index) in produto.validadesAdicionais"
                            :key="validades.validade"
                        >
                            {{ dataFormatada(validades.validade) }} - QTD: {{ validades.quantidade }}
                            <div class="container-buttons">
                                <router-link
                                    :to="{
                                        name: 'produtos.edit',
                                        params: {
                                            codigo: produto.codigo_de_barras,
                                            validade: validades.validade,
                                            quantidade: validades.quantidade,
                                        },
                                    }"
                                    class="btn-edit-validade"
                                >
                                    <img src="../assets/img/edit.svg" alt="Editar" />
                                </router-link>
                                <button
                                    v-if="produto.validadesAdicionais.length > 1"
                                    @click.prevent="excluiValidade(validades.validade, index)"
                                    class="btn-remove-validade"
                                >
                                    <img src="../assets/img/close-black.svg" alt="Remover validade" />
                                </button>
                            </div>
                        </li>
                    </ul>
                </h2>
            </div>
            <div class="container categoria">
                <h3>Categoria</h3>
                <h2>{{ produto.categoria }}</h2>
            </div>
            <div class="container categoria-tarefa">
                <h3>Categoria_Tarefa</h3>
                <h2>{{ produto.categoria_tarefa }}</h2>
            </div>
            <div class="container produto">
                <h3>Produto</h3>
                <div class="container-img">
                    <img
                        :src="
                            'https://147266017877-app-images.s3.sa-east-1.amazonaws.com/' +
                            produto.codigo_de_barras +
                            '.webp'
                        "
                        @error="replaceByDefault"
                        alt="imagem do produto"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import img from '../assets/img/broken-image.svg';
export default {
    data() {
        return {
            produto: {},
        };
    },

    computed: {
        getProduto() {
            return this.$store.getters['inclusao/getProduto'];
        },
        getCodigo() {
            return this.$store.getters['inclusao/getCodigo'];
        },
    },

    created() {
        let codigo = this.$route.params.codigo ? this.$route.params.codigo : this.getCodigo;
        this.$store.dispatch('inclusao/setCodigo', codigo);

        this.produto = this.getProduto;
    },

    methods: {
        dataFormatada(diaDB) {
            let data = new Date(diaDB + ' 00:00');
            return data.toLocaleDateString();
        },

        replaceByDefault(e) {
            e.target.src = img;
        },

        excluiValidade(validade, index) {
            this.removeValidade(index);
        },

        removeValidade(currentIndex) {
            this.produto.validadesAdicionais.splice(
                this.produto.validadesAdicionais.findIndex((a, index) => index == currentIndex),
                1
            );
        },
    },
};
</script>

<style scoped>
.main-container {
    margin-top: 56px;
    padding: 20px;
    margin-bottom: 50px;
}

.container {
    color: #3c4043;
    margin-bottom: 14px;
}

.container h3 {
    font-size: 0.6em;
}

.container h2 {
    font-size: 0.9em;
    margin-top: 4px;
}

.container-img {
    width: 100%;
    display: flex;
    justify-content: left;
    margin-top: 4px;
}

.container-img img {
    width: 80px;
    height: 80px;
}

.linha-validade {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    margin-bottom: 10px;
    border: 1px solid #d8d8d8;
    padding: 5px;
    border-radius: 10px;
}

.container-buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.btn-edit-validade {
    background-color: #e3e3e3;
    border: none;
    border-radius: 10px;
    padding: 5px;
    display: inline-flex;
    cursor: pointer;
    justify-self: flex-end;
}

.btn-edit-validade:hover {
    background-color: #f4f4f4;
}

.btn-edit-validade img {
    width: 18px;
}

.btn-remove-validade {
    background-color: #e3e3e3;
    border: none;
    border-radius: 10px;
    padding: 5px;
    display: inline-flex;
    cursor: pointer;
    justify-self: flex-end;
}

.btn-remove-validade:hover {
    background-color: #f4f4f4;
}

.btn-remove-validade img {
    width: 18px;
}

@media (max-width: 319px) {
    .container h2 {
        font-size: 0.8rem;
    }

    .btn-add-container {
        left: 76%;
    }
}
</style>
